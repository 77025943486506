<template>
  <base-section
    id="ansoncare"
  >
    <v-container>
      <v-row>
        <v-col
          cols="6"
        >
          <v-img
           :src="require('@/assets/ANSONCARE LOGO.png')"
           :height="$vuetify.breakpoint.mdAndUp ? 400 : 225"
           contain
          >
          </v-img>
        </v-col>
        <v-col
          cols="6"
        >
          <v-img
           :src="require('@/assets/Ansoncare Box.png')"
           :height="$vuetify.breakpoint.mdAndUp ? 400 : 225"
           contain
          >
          </v-img>
        </v-col>
      </v-row>
      <base-section-heading title="Start Your Glove Business Journey with Us">
      </base-section-heading>
      <base-body
        html="&nbsp;&nbsp;Embark on your glove business journey with us, a leading OEM glove manufacturer committed to empowering entrepreneurs and businesses like yours. <br><br>&nbsp;&nbsp;Discover the ultimate hand protection experience with our exclusive in-house glove brand, Ansoncare. We invite you to try our premium gloves that have been meticulously crafted to deliver unmatched comfort, durability, and performance. Whether you're in the medical field, industrial sector, food processing, or any other industry, Ansoncare gloves are designed to meet your specific needs. <br><br>&nbsp;&nbsp; Our gloves are thoughtfully engineered to provide a snug fit, excellent grip, and exceptional dexterity, enabling you to work with confidence and ease."
      >
      </base-body>
    </v-container>
    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAnsoncare',

    data: () => ({
      features: [
        {
          title: 'Superior Material Selection',
          icon: 'mdi-star',
          dark: true,
          color: 'primary',
          text: 'Crafted from the finest materials, our premium gloves offer exceptional strength, tear resistance, and flexibility, ensuring long-lasting durability without compromising on comfort.',
        },
        {
          title: 'Optimal Sensitivity and Dexterity',
          icon: 'mdi-star',
          dark: true,
          color: 'primary',
          text: 'Our gloves offer excellent tactile sensitivity, allowing for precise handling of delicate items and intricate tasks, making them ideal for various industries, including medical, dental, laboratory, and more.',
        },
        {
          title: 'Advanced Barrier Protection',
          icon: 'mdi-star',
          dark: true,
          color: 'primary',
          text: 'With a reliable barrier against pathogens, chemicals, and other contaminants, our premium disposable gloves ensure a high level of protection, meeting industry standards and regulations.',
        },
        {
          title: 'Easy Donning and Doffing',
          icon: 'mdi-star',
          dark: true,
          color: 'primary',
          text: 'The gloves are designed for easy donning and quick removal, optimizing efficiency in fast-paced work environments.',
        },
        {
          title: 'Versatility',
          icon: 'mdi-star',
          dark: true,
          color: 'primary',
          text: 'From medical professionals to food handlers, our premium gloves serve a multitude of industries, meeting the diverse needs of our valued customers.',
        },
        {
          title: 'Quality Assurance',
          icon: 'mdi-star',
          dark: true,
          color: 'primary',
          text: 'Each pair of premium gloves undergoes rigorous testing and quality control processes, guaranteeing that you receive a product that meets the highest standards.',
        },
      ],
    }),
  }
</script>
